import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import ReactSelect from 'react-select'
import { find, prop, propEq } from 'ramda'
import InputLabel from 'components/InputLabel'
import selectStyles from './selectStyles'

const SelectWrapper = styled('div')`
  width: 220px;
  min-width: 220px;
`

const noOptionsMessage = ({ inputValue }) => {
  if (inputValue) return `Не найдено "${inputValue}"`
  return 'Не найдено'
}
const loadingMessage = ({ inputValue }) => {
  return 'Загрузка'
}

const getSelectedOption = (options, option) => {
  return find(propEq('value', prop('value', option) || option))(options)
}

const Select = props => {
  const {
    theme,
    value,
    options,
    label,
    onChange,
    type,
    onMenuOpen,
    onMenuClose,
    ...rest
  } = props
  const [menuIsOpen, setMenuIsOpen] = React.useState(false)

  const handleMenuOpen = () => {
    setMenuIsOpen(true)
    if (typeof onMenuOpen === 'function') {
      onMenuOpen()
    }
  }

  const handleMenuClose = () => {
    setMenuIsOpen(false)
    if (typeof onMenuClose === 'function') {
      onMenuClose()
    }
  }

  const selectedValue = getSelectedOption(options, value)

  return (
    <SelectWrapper>
      <InputLabel>{label}</InputLabel>
      <ReactSelect
        value={selectedValue}
        options={options}
        classNamePrefix={'select'}
        styles={selectStyles(theme, {
          menuIsOpen
        })}
        closeMenuOnSelect={!rest.isMulti}
        menuIsOpen={menuIsOpen}
        openMenuOnClick={type === 'select'}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        onChange={(option, action) => {
          if (typeof onChange === 'function') {
            onChange(prop('value', option))
          }
        }}
        menuPortalTarget={document.body}
        {...rest}
      />
    </SelectWrapper>
  )
}

Select.propTypes = {
  theme: PropTypes.object,
  value: PropTypes.any,
  label: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf([
    'select',
    'autocomplete'
  ]),
  isMulti: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func
}

Select.defaultProps = {
  type: 'select',
  placeholder: 'Выберите из списка',
  isClearable: true
}

export default withTheme(Select)
