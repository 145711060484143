import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import inputBaseStyle from './inputBaseStyle'

const transition = 'all 150ms ease-out'

const ToggleContainer = styled('div')({
  ...inputBaseStyle,
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'flex'
})

const Label = styled('div')`
  font-size: 14px;
  margin-right: 20px;
`

const ToggleTrigger = styled('div')`
  background-color: ${({ isChecked, ...props }) => isChecked
    ? props.theme.palette.primaryColor : '#d2d8e4'};
  border-radius: 50px;
  cursor: pointer;
  position: relative;
  transition: ${transition};
  height: 20px;
  width: 36px;
  &:after {
    background-color: white;
    border-radius: 50%;
    content: '';
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: ${({ isChecked }) => isChecked ? '2px' : '18px'};
    transition: ${transition};
    height: 16px;
    width: 16px;
  }
`

const Toggle = props => {
  const { label, checked, onChange } = props

  const [isChecked, setIsChecked] = useState(checked)

  const onChangeToggle = () => {
    setIsChecked(!isChecked)
    if (onChange) onChange(!isChecked)
  }

  return (
    <ToggleContainer>
      <Label>{label}</Label>
      <ToggleTrigger
        isChecked={isChecked}
        onClick={onChangeToggle}
      />
    </ToggleContainer>
  )
}

Toggle.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func
}

Toggle.defaultProps = {
  checked: false
}

export default Toggle
