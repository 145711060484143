import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { Area, AreaChart, Tooltip, XAxis, YAxis } from 'recharts'
import { chartTooltipProps } from '../helpers'

const ChartWrap = styled('div')`
  margin-top: 30px;
`

const Chart = props => {
  const { theme, data } = props

  const chartRef = useRef()
  const [chartWidth, setChartWidth] = useState(0)

  useEffect(() => {
    const container = chartRef.current
    const containerWidth = container.clientWidth
    setChartWidth(containerWidth)
  }, [])

  return (
    <ChartWrap ref={chartRef}>
      <AreaChart
        width={chartWidth}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 20,
          left: -30,
          bottom: 5
        }}
      >
        <XAxis dataKey="hour" />
        <YAxis />
        <Tooltip {...chartTooltipProps} />

        <Area
          type="monotone"
          dataKey="compareCount"
          stroke={theme.palette.primaryColor}
          fill={theme.palette.primaryColor}
        />
        <Area
          type="monotone"
          dataKey="count"
          stroke={theme.palette.greenColor}
          fill={theme.palette.greenColor}
        />
      </AreaChart>
    </ChartWrap>
  )
}

Chart.propTypes = {
  theme: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired
}

export default withTheme(Chart)
