import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DatePickerDP, { registerLocale } from 'react-datepicker'
import styled from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import format from 'date-fns/format'
import ruRu from 'date-fns/locale/ru'
import { Calendar } from 'react-feather'
import InputLabel from '../InputLabel'
import inputBaseStyle from './inputBaseStyle'

registerLocale('ru', ruRu)

const Wrapper = styled('div')`
  z-index: 1;
  & .react-datepicker__day--outside-month {
    opacity: 0.35;
  }
`

const InputContainer = styled('div')`
  position: relative;
`

const StyledIcon = styled(Calendar)`
  color: ${props => props.theme.palette.primaryColor};
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 1;
`

const StyledDatePicker = styled(DatePickerDP)({
  ...inputBaseStyle,
  padding: '10px 12px 10px 38px',
  width: '220px',
  boxShadow: 'none'
})

const DatePicker = props => {
  const { id, label, onChange, value, ...rest } = props
  const [selectedDate, setSelectedDate] = useState(value)

  const onChangeDate = (date) => {
    setSelectedDate(date)
    if (onChange) {
      if (date) onChange(format(date, 'yyyy-MM-dd'))
      else onChange(null)
    }
  }

  return (
    <Wrapper>
      <InputLabel>{label}</InputLabel>

      <InputContainer>
        <StyledIcon size={18} />
        <StyledDatePicker
          {...rest}
          selected={selectedDate}
          dateFormat={'dd MMM yyyy'}
          locale={'ru'}
          onChange={onChangeDate}
          // withPortal={true}
          portalId={'date-portal'}
        />
      </InputContainer>
    </Wrapper>
  )
}

DatePicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.instanceOf(Date)
}

DatePicker.defaultProps = {
  placeholderText: 'Выберите дату'
}

export default DatePicker
