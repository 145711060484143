import axios, { getPayloadFromError, getPayloadFromSuccess } from 'utils/axios'
import { storageData } from 'utils/storage'
import * as actionTypes from 'constants/actionTypes'
import * as API from 'constants/api'

export const setTokenAction = () => {
  return {
    type: `${actionTypes.AUTH}_FULFILLED`,
    payload: { token: storageData('token').getValue() }
  }
}

export const logoutAction = () => {
  localStorage.clear()
  return {
    type: actionTypes.AUTH,
    payload: Promise.resolve(null)
  }
}

export const signInAction = values => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .post(API.SIGN_IN, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.AUTH,
      payload
    })
  }
}
