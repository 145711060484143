import * as ROUTES from 'constants/routes'
import Layout, { BlankLayout } from 'components/Layouts'
import NotFound from 'modules/not-found'
import { SignInContainer } from 'containers/sign-in'
import { HomeContainer } from 'containers/home'

export default [
  // SIGN IN
  {
    path: ROUTES.SIGN_IN_PATH,
    layout: BlankLayout,
    component: SignInContainer
  },
  // HOME
  {
    path: ROUTES.ROOT_PATH,
    layout: Layout,
    component: HomeContainer
  },
  // NOT FOUND
  {
    path: '*',
    layout: Layout,
    component: NotFound
  }
]
