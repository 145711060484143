import React from 'react'
import PropTypes from 'prop-types'
import { defaultTo, find, map, pipe, prop, propEq } from 'ramda'
import { getHoursData } from '../helpers'
import Card from './Card'
import CardTitle from './CardTitle'
import CardValue from './CardValue'
import Comparsion from './Comparsion'
import Chart from './Chart'

const DivisionCard = props => {
  const { filter, data, compareData } = props

  const division = prop('division', data)
  const dates = prop('dates', data)
  const cumulativeInCounts = prop('cumulativeInCounts', data)

  const compareDates = prop('dates', compareData) || []
  const compareCumulativeInCounts = prop('cumulativeInCounts', compareData)

  const formedHoursArray = getHoursData(dates)
  const formedData = map(item => {
    const comparesHourCount = pipe(
      find(propEq('hour', item.hour)),
      prop('count'),
      defaultTo(0)
    )(compareDates)
    return {
      ...item,
      compareCount: comparesHourCount
    }
  }, formedHoursArray)

  return (
    <Card>
      <CardTitle>{division}</CardTitle>
      <CardValue>Total: {cumulativeInCounts}</CardValue>

      <Comparsion
        filter={filter}
        totalInCount={cumulativeInCounts}
        totalCompareInCount={compareCumulativeInCounts}
      />

      <Chart data={formedData} />
    </Card>
  )
}

DivisionCard.propTypes = {
  filter: PropTypes.object,
  data: PropTypes.object,
  compareData: PropTypes.object
}

export default DivisionCard
