import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { find, pipe, prop } from 'ramda'
import styled from 'styled-components'
import { devices } from 'constants/mediaQueries'
import Loader from 'components/Loader'
import ChartFilter from './ChartFilter'
import TotalChart from './TotalChart'
import DivisionCard from './DivisionCard'

const Wrapper = styled('div')`

`

const Divisions = styled('div')`
  display: grid;
  grid-template-columns: none;
  grid-gap: 30px;
  margin-top: 30px;
  @media (min-width: ${devices.tabletL}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const getDivisionUniqKey = item => {
  const division = prop('division', item)
  const zoneId = prop('zoneId', item)
  return `${division}_${zoneId}`
}

const Home = props => {
  const { list, listCompare, filter } = props

  const data = prop('data', list) || []
  const loading = prop('loading', list)

  const dataCompare = prop('data', listCompare) || []
  const loadingCompare = prop('loading', listCompare)

  return (
    <Wrapper>
      <ChartFilter {...filter} />

      {(loading || loadingCompare)
        ? <Loader />
        : (
          <Fragment>
            <TotalChart
              filter={filter}
              data={data}
              dataCompare={dataCompare}
            />

            <Divisions>
              {data.map((item, index) => {
                const id = getDivisionUniqKey(item)
                const compareItem = pipe(find(item => getDivisionUniqKey(item) === id))(dataCompare)

                return (
                  <DivisionCard
                    key={index}
                    data={item}
                    compareData={compareItem}
                    filter={filter}
                  />
                )
              })}
            </Divisions>
          </Fragment>
        )}
    </Wrapper>
  )
}

Home.propTypes = {
  list: PropTypes.object.isRequired,
  listCompare: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired
}

export default Home
