import { combineReducers } from 'redux'
import * as actionTypes from '../constants/actionTypes'
import * as STATES from '../constants/states'
import createThunkReducer from '../utils/createThunkReducer'

export default combineReducers({
  [STATES.AUTH]: createThunkReducer(actionTypes.AUTH),
  [STATES.MARKET_STATS_LIST]: createThunkReducer(actionTypes.MARKET_STATS_LIST),
  [STATES.MARKET_STATS_LIST_ALL]: createThunkReducer(actionTypes.MARKET_STATS_LIST_ALL)
})
