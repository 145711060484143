import React from 'react'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import RouteWithSubRoutes from './RouteWithSubRoutes'
import NormalizeCSS from './NormalizeCSS'
import GlobalStyles from './GlobalStyles'
import routes from '../routes'
import store from '../store'

const theme = {
  palette: {
    backgroundColor: '#FAFBFD',
    primaryColor: '#8884d8',
    textColor: '#273D56',
    redColor: '#ff4324',
    greenColor: '#82ca9d'
  },
  table: {
    backgroundColor: '#F2F4F8',
    border: '1px solid #EBEEF4'
  },
  input: {
    backgroundColor: '#FAFBFC',
    borderColor: '#E4E7EE',
    borderRadius: '5px',
    labelColor: '#8290A0',
    placeholderColor: '#AEB7C6'
  },

  boxShadow: '0px 10px 15px rgba(211, 216, 224, 0.5)',
  transition: 'all 300ms ease-out'
}

const App = () => {
  return (
    <Provider store={store()}>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <NormalizeCSS />
          <GlobalStyles />
          <Router>
            <Switch>
              {routes.map((route, key) => (
                <RouteWithSubRoutes key={key} {...route} />
              ))}
            </Switch>
          </Router>
        </React.Fragment>
      </ThemeProvider>
    </Provider>
  )
}

export default App
