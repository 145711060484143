import { path, prop, pipe, not } from 'ramda'
import { compose, mapPropsStream } from 'recompose'
import { first, filter } from 'rxjs/operators'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import { SIGN_IN_PATH } from 'constants/routes'
import { setTokenAction } from 'modules/sign-in/actions'

const getTokenFromState = path(['auth', 'data', 'token'])

const mapStateToProps = state => ({
  token: getTokenFromState(state)
})

export default compose(
  connect(mapStateToProps, { setTokenAction }),
  mapPropsStream(props$ => {
    props$
      .pipe(
        first(),
        filter(pipe(
          prop('token'),
          not
        ))
      )
      .subscribe(props => props.setTokenAction())

    return props$
  }),
  connectedRouterRedirect({
    redirectPath: SIGN_IN_PATH,
    authenticatedSelector: state => Boolean(getTokenFromState(state)),
    // authenticatedSelector: state => true,
    redirectAction: routerActions.replace,
    wrapperDisplayName: 'UserIsAuthenticated'
  })
)
