import React from 'react'
import styled, { keyframes } from 'styled-components'

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Wrap = styled('div')`
  display: flex;
  justify-content: center;
  padding: 20px 0;
`

const Load = styled('div')`
  display: inline-block;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  :after{
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid;
    border-color: #555 transparent #555 transparent;
    animation: ${animation} 1.2s linear infinite;
  }
`

const Loader = () => {
  return (
    <Wrap>
      <Load />
    </Wrap>
  )
}

export default Loader
