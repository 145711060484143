import React from 'react'
import PropTypes from 'prop-types'
import { max, path } from 'ramda'
import format from 'date-fns/format'
import styled from 'styled-components'
import { getYesterday } from '../helpers'

const Wrapper = styled('div')`
  margin: 20px 0;
`

const ComparsionWrap = styled('div')`
  background-color: #e4e4e4;
  border-radius: 2px;
  position: relative;
  height: 5px;
  width: 300px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

const ComparsionLabel = styled('div')`
  font-size: 1.2em;
  margin-bottom: 5px;
`

const ComparsionValue = styled('div')`
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${props => props.percent + '%'};
`

const DateValue = styled(ComparsionValue)`
  background-color: ${props => props.theme.palette.greenColor};
`

const CompareValue = styled(ComparsionValue)`
  background-color: ${props => props.theme.palette.primaryColor};
`

const today = format(new Date(), 'yyyy-MM-dd')
const yesterday = getYesterday()

const Comparsion = props => {
  const { filter, totalInCount, totalCompareInCount } = props

  const filterInitDate = path(['initialValues', 'datetime'], filter) || today
  const filterCompareDate = path(['initialValues', 'datetime2'], filter) || yesterday

  const humanizedInitDate = format(new Date(filterInitDate), 'dd MMM yyyy')
  const humanizedCompareDate = format(new Date(filterCompareDate), 'dd MMM yyyy')

  const comparsionMaxValue = max(totalInCount, totalCompareInCount)
  const dateValueWidth = (totalInCount / comparsionMaxValue) * 100
  const compareValueWidth = (totalCompareInCount / comparsionMaxValue) * 100

  return (
    <Wrapper>
      <ComparsionLabel>{humanizedInitDate}: {totalInCount}</ComparsionLabel>
      <ComparsionWrap>
        <DateValue percent={dateValueWidth} />
      </ComparsionWrap>

      <ComparsionLabel>{humanizedCompareDate}: {totalCompareInCount}</ComparsionLabel>
      <ComparsionWrap>
        <CompareValue percent={compareValueWidth} />
      </ComparsionWrap>
    </Wrapper>
  )
}

Comparsion.propTypes = {
  filter: PropTypes.object,
  totalInCount: PropTypes.number,
  totalCompareInCount: PropTypes.number
}

export default Comparsion
