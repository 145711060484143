import { path } from 'ramda'
import { compose, pure, mapPropsStream } from 'recompose'
import { distinctUntilChanged } from 'rxjs/operators'
import { connect } from 'react-redux'
import { getDataFromState, getListParams } from 'utils/get'
import { isEqualSearch, DEFAULT_PICK_PARAMS } from 'utils/isEquals'

const withFetchList = params => {
  const {
    stateName,
    action,
    key = 'list',
    mapper = getListParams,
    pickParams = DEFAULT_PICK_PARAMS
  } = params

  const mapStateToProps = state => ({
    [key]: getDataFromState(stateName, state)
  })
  const mapDispatchToProps = { action }

  return compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    mapPropsStream(props$ => {
      props$
        .pipe(
          distinctUntilChanged(
            isEqualSearch(pickParams),
            path(['history', 'location', 'search'])
          )
        )
        .subscribe(props => props.action(mapper(props, pickParams)))

      return props$
    }),
    pure
  )
}

export default withFetchList
