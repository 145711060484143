import axios from 'axios'
import { prop, compose, path, curry, equals } from 'ramda'
import { API_URL } from 'constants/api'
import { AUTH } from 'constants/actionTypes'
import { storageData } from './storage'
import responseToCamelCase from './responseToCamelCase'

export const getPayloadFromSuccess = prop('data')

export const getPayloadFromError = compose(
  data => Promise.reject(data),
  path(['response', 'data'])
)

const UNAUTHORIZED = 401
const axiosConfig = {
  baseURL: API_URL,
  transformResponse: [responseToCamelCase]
}

const errorInterceptors = curry((dispatch, error) => {
  const status = path(['response', 'status'], error)

  if (equals(UNAUTHORIZED, status)) {
    dispatch({ type: `${AUTH}_CLEAR` })
  }

  return Promise.reject(error)
})

export default ({ dispatch, getState }) => {
  const state = getState()
  const token = path(['auth', 'data', 'token'], state) || storageData('token').getValue()

  const headers = {
    common: { Authorization: token ? `Token ${token}` : '' }
  }

  const instance = axios.create({ ...axiosConfig, headers })

  instance.interceptors.response.use(
    response => response,
    errorInterceptors(dispatch)
  )

  return instance
}
