import React from 'react'
import ReactDOM from 'react-dom'
import { setObservableConfig } from 'recompose'
import { from } from 'rxjs'
import * as serviceWorker from './serviceWorker'
import App from './components/App'

setObservableConfig({
  // Converts a plain ES observable to an RxJS 6 observable
  fromESObservable: from
})

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
