import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DisplayFlex } from '../Styled'

const FilterContainer = styled(DisplayFlex)`
  flex-flow: row wrap;
  align-items: flex-end;
  margin-bottom: 16px;
  & > div {
    margin: 0 16px 10px 0;
  }
`

const Filter = props => {
  return (
    <FilterContainer>
      {props.children}
    </FilterContainer>
  )
}

Filter.propTypes = {
  children: PropTypes.node.isRequired
}

export default Filter
