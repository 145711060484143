import { prop } from 'ramda'
import format from 'date-fns/format'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import axios, { getPayloadFromError, getPayloadFromSuccess } from 'utils/axios'
import { getYesterday } from '../helpers'

const today = format(new Date(), 'yyyy-MM-dd')

export const marketStatsListAction = params => {
  const datetime = prop('datetime', params) || today
  const formedParams = { datetime }

  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(API.MARKET_STATS_LIST, { params: formedParams })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKET_STATS_LIST,
      payload
    })
  }
}

export const marketStatsListAllAction = params => {
  const yesterday = getYesterday()
  const datetime = prop('datetime2', params) || yesterday
  const formedParams = { datetime }

  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(API.MARKET_STATS_LIST, { params: formedParams })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKET_STATS_LIST_ALL,
      payload
    })
  }
}
