import React from 'react'
import { compose } from 'recompose'
import * as STATES from 'constants/states'
import { marketStatsListAction, marketStatsListAllAction } from 'modules/home/actions'
import { useFilter } from 'components/Hooks'
import { withFetchList } from 'components/HOCs'
import Home from 'modules/home/components/Home'

const enhance = compose(
  withFetchList({
    stateName: STATES.MARKET_STATS_LIST,
    action: marketStatsListAction,
    pickParams: ['datetime']
  }),
  withFetchList({
    key: 'listCompare',
    stateName: STATES.MARKET_STATS_LIST_ALL,
    action:marketStatsListAllAction,
    pickParams: ['datetime2']
  })
)

export default enhance(props => {
  const { list, listCompare } = props

  const filter = useFilter({ fields: ['datetime', 'datetime2'] })

  return (
    <Home
      list={list}
      listCompare={listCompare}
      filter={filter}
    />
  )
})
