import { map, mapObjIndexed, propOr } from 'ramda'
import { useHistory } from 'react-router-dom'
import { getInitValuesFromHistory } from 'utils/get'
import { addParamsRoute } from 'utils/route'

const getVal = value => propOr(value, 'id', value)
const getIds = map(getVal)

export default params => {
  const {
    fields,
    mapValues = getIds,
    mapInitValues = mapObjIndexed(item => item)
  } = params

  const history = useHistory()

  const onChangeFilter = values => {
    addParamsRoute({ ...mapValues(values) }, history)
  }

  const initialValues = mapInitValues(getInitValuesFromHistory(fields, history))

  return {
    onChangeFilter,
    initialValues
  }
}
