import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import * as ROUTES from 'constants/routes'
import { logoutAction } from 'modules/sign-in/actions'
import Container from './Container'
import AuthWrapper from '../AuthWrapper'
import { mediaQueries } from 'constants/mediaQueries'

const enhance = compose(
  withRouter,
  AuthWrapper
)

const Content = styled('div')`
  padding: 50px 0;
  @media ${mediaQueries.tabletL}{
    padding: 30px 0;
  }
`

const Layout = props => {
  const { children, history } = props

  const dispatch = useDispatch()

  const onLogout = () => {
    dispatch(logoutAction())
      .then(() => history.replace(ROUTES.SIGN_IN_PATH))
  }

  return (
    <React.Fragment>
      <Container>
        <Content>
          {children}
        </Content>
      </Container>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired
}

export default enhance(Layout)
