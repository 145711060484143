import { find, map, propEq, range } from 'ramda'
import format from 'date-fns/format'

export const getHoursData = dates => {
  const hours = range(0, 24)
  return map(h => {
    const hour = find(propEq('hour', h), dates)
    return hour || { hour: h, count: 0 }
  }, hours)
}

const tooltipNames = {
  count: 'Кол-во',
  compareCount: 'Кол-во (сравнение)'
}

export const chartTooltipProps = {
  labelFormatter: value => `Время: ${value}:00`,
  formatter: (value, name) => [value, tooltipNames[name]]
}

export const getYesterday = () => {
  const date = new Date()
  date.setDate(date.getDate() - 1)
  return format(date, 'yyyy-MM-dd')
}
