import React from 'react'
import PropTypes from 'prop-types'
import { defaultTo, find, flatten, head, map, pipe, pluck, prop, propEq, reduce, sum, values } from 'ramda'
import styled, { withTheme } from 'styled-components'
import { getHoursData } from '../helpers'
import Card from './Card'
import CardTitle from './CardTitle'
import CardValue from './CardValue'
import Comparsion from './Comparsion'
import Chart from './Chart'

const Differences = styled('div')`
  margin-top: 10px;
`

const DifferenceValue = styled('div')`
  font-weight: 500;
  & > span {
    color: ${props => props.color};
  }
`

const getTotalDates = data => {
  return pipe(
    map(prop('dates')),
    flatten,
    reduce((a, { hour, count }) => {
      a[hour] = a[hour] || { hour, count: 0 }
      a[hour].count += count
      return a
    }, {}),
    values
  )(data)
}

const TotalChart = props => {
  const { theme, filter, data, dataCompare } = props

  const storeId = pipe(head, prop('storeId'))(data)
  const totalInCount = pipe(pluck('cumulativeInCounts'), sum)(data)
  const totalCompareInCount = pipe(pluck('cumulativeInCounts'), sum)(dataCompare)

  const countDifference = totalInCount - totalCompareInCount
  const percentDifference = totalInCount === 0
    ? 100
    : ((countDifference / totalInCount) * 100).toFixed(2)
  const differenceColor = countDifference > 0
    ? theme.palette.greenColor
    : countDifference < 0
      ? theme.palette.redColor
      : 'inherit'

  const totalDates = getTotalDates(data)
  const compareTotalDates = getTotalDates(dataCompare)

  const formedHoursArray = getHoursData(totalDates)
  const formedData = map(item => {
    const comparesHourCount = pipe(
      find(propEq('hour', item.hour)),
      prop('count'),
      defaultTo(0)
    )(compareTotalDates)
    return {
      ...item,
      compareCount: comparesHourCount
    }
  }, formedHoursArray)

  return (
    <Card>
      <CardTitle>{storeId}</CardTitle>
      <CardTitle>Total: {totalInCount}</CardTitle>
      <Differences>
        <CardValue>
          <DifferenceValue color={differenceColor}>
            Difference: <span>{countDifference}</span>
          </DifferenceValue>
        </CardValue>
        <CardValue>
          <DifferenceValue color={differenceColor}>
            Diff. percent: <span>{percentDifference}%</span>
          </DifferenceValue>
        </CardValue>
      </Differences>

      <Comparsion
        filter={filter}
        totalInCount={totalInCount}
        totalCompareInCount={totalCompareInCount}
      />

      <Chart data={formedData} />
    </Card>
  )
}

TotalChart.propTypes = {
  theme: PropTypes.object,
  filter: PropTypes.object,
  data: PropTypes.array,
  dataCompare: PropTypes.array
}

export default withTheme(TotalChart)
