import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'ramda/es/prop'
import { User, Lock } from 'react-feather'
import hexToRgb from 'utils/hexToRgb'
import Button from 'components/Button'
import { IconInput } from 'components/FormFields/Input'

const Wrapper = styled('div')`
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
`

const Form = styled('form')`
  max-width: 325px;
  width: 100%;
`

const Field = styled('div')`
  margin-bottom: 20px;
`

const SubmitButton = styled(Button)`
  background-color: ${props => props.theme.palette.primaryColor};
  color: white;
  font-weight: 500;
  display: block;
  height: 46px;
  margin-top: 30px;
  width: 100%;
`

const Error = styled('div')`
  background-color: ${props => hexToRgb(props.theme.palette.redColor, '0.2')};
  border-radius: 5px;
  color: ${props => props.theme.palette.redColor};
  margin-bottom: 15px;
  padding: 5px 15px;
`

const initialState = { username: '', password: '' }
const reducer = (state, action) => ({ ...state, ...action })

const SignIn = props => {
  const { auth, onSubmit } = props

  const [values, dispatch] = useReducer(reducer, initialState)

  const onSubmitForm = event => {
    event.preventDefault()
    onSubmit(values)
  }

  const authLoading = prop('loading', auth)
  const authFailed = prop('failed', auth)

  return (
    <Wrapper>
      <Form onSubmit={onSubmitForm}>
        {authFailed && <Error>Неверные учетные данные</Error>}
        <Field>
          <IconInput
            icon={User}
            type={'text'}
            placeholder={'Логин'}
            value={values.username}
            onChange={event => dispatch({ username: event.target.value })}
          />
        </Field>
        <Field>
          <IconInput
            icon={Lock}
            type={'password'}
            placeholder={'Пароль'}
            value={values.password}
            onChange={event => dispatch({ password: event.target.value })}
          />
        </Field>
        <SubmitButton disabled={authLoading}>Войти</SubmitButton>
      </Form>
    </Wrapper>
  )
}

SignIn.propTypes = {
  auth: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default SignIn
