import React from 'react'
import PropTypes from 'prop-types'
import { DatePicker } from 'components/FormFields'
import Filter from 'components/Filter'
import { getYesterday } from '../helpers'

const getDate = (date, defaultDate = null) => {
  if (date) return new Date(date)
  return defaultDate
}

const ChartFilter = ({ onChangeFilter, initialValues }) => {
  const today = new Date()
  const yesterday = new Date(getYesterday())

  return (
    <Filter>
      <DatePicker
        label={'Дата'}
        value={getDate(initialValues.datetime, today)}
        onChange={value => onChangeFilter({ datetime: value })}
      />
      <DatePicker
        label={'Дата сравнения'}
        value={getDate(initialValues.datetime2, yesterday)}
        onChange={value => onChangeFilter({ datetime2: value })}
      />
    </Filter>
  )
}

ChartFilter.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default ChartFilter
